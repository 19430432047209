<template>
  <section>
    <table-accounting />
  </section>
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import TableAccounting from "../../components/TableAccounting";
import { FETCHING_RECORDS } from "../../store/modules/accounting/accounting.actions.type";
import { FETCHING_CUSTOMERS } from "../../store/modules/customer/customer.actions.type";
import { GET_CUSTOMERS } from "../../store/modules/customer/customer.getters.type";

export default {
  name: "CustomerAccounting",
  components: { TableAccounting },
  setup() {
    const route = useRoute();
    const { customerId } = route.params;
    const store = useStore();

    onMounted(() => {
      if (store.getters[GET_CUSTOMERS].length === 0) {
        store.dispatch(FETCHING_CUSTOMERS);
      }
      store.dispatch(FETCHING_RECORDS, customerId);
    });
  },
};
</script>
