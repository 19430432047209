<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <Toolbar class="p-mb-4">
        <template #left>
          <h5 class="p-m-0">
            <a v-if="customer" role="link" @click="onCustomers">{{
              customer.company
            }}</a
            >&nbsp;/&nbsp;
            {{ $t("customer.accounting.table_title") }}
          </h5>
        </template>

        <template #right>
          <Button
            :label="translation.buttonInvitation"
            class="p-button-secondary p-button-outlined p-mr-2 p-mb*2"
            data-testid="buttonOpenSendDialog"
            @click="showInvitationModal = true"
          />
          <Button
            :label="translation.buttonRecord"
            icon="pi pi-plus"
            class="p-button-secondary p-mr-2 p-mb*2"
            data-testid="buttonOpenAppendDialog"
            @click="showAppendModal = true"
          />
        </template>
      </Toolbar>
      <DataTable
        :value="records"
        :paginator="true"
        :resizable-columns="true"
        column-resize-mode="expand"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
      >
        <Column field="createdAt" :header="translation.createdAt">
          <template #body="slotProps">
            <span>{{ stringyDate(slotProps.data.createdAt) }}</span>
          </template>
        </Column>
        <Column
          field="description"
          :header="translation.description"
          :sortable="true"
        />
        <Column field="time" :header="translation.time" :sortable="true">
          <template #body="slotProps">
            <span>{{ stringyTime(slotProps.data.increaseTime) }}</span>
          </template>
        </Column>
        <Column field="time" :header="translation.storage" :sortable="true">
          <template #body="slotProps">
            <span>{{ stringyStorage(slotProps.data.maxStorage) }}</span>
          </template>
        </Column>
        <Column field="expires" :header="translation.expires">
          <template #body="slotProps">
            <span>{{ stringyDate(slotProps.data.expires) }}</span>
          </template>
        </Column>
      </DataTable>

      <Dialog
        v-model:visible="showAppendModal"
        :style="{ width: '450px' }"
        :header="translation.dialogAppendTitle"
        :modal="true"
        class="p-fluid p-pb-0"
      >
        <div class="p-field">
          <label for="description">{{
            $t("customer.accounting.new.field_description")
          }}</label>
          <InputText
            id="description"
            v-model="record.description"
            aria-label="description"
          />
        </div>
        <div class="p-field">
          <label for="time">{{
            $t("customer.accounting.new.field_time")
          }}</label>
          <InputNumber
            id="time"
            v-model="increasedTimeInMin"
            data-testid="time"
            show-buttons
            aria-label="time"
            suffix=" min."
            :min="0"
          />
        </div>
        <div class="p-field">
          <label for="storage">{{
            $t("customer.accounting.new.field_storage")
          }}</label>
          <InputNumber
            id="storage"
            v-model="maxStorageInGB"
            data-testid="storage"
            show-buttons
            aria-label="storage"
            suffix=" Gb."
            :min="0"
          />
        </div>
        <div class="p-field">
          <label for="expiresAt">{{
            $t("customer.accounting.new.field_expires")
          }}</label>
          <Calendar
            id="expiresAt"
            v-model="record.expires"
            aria-label="expiresAt"
            :min-date="minDate"
            :month-navigator="true"
            :year-navigator="true"
            :show-icon="true"
            :year-range="rangeYears"
          />
        </div>
        <template #footer>
          <Button
            :label="translation.buttonCancel"
            icon="pi pi-times"
            class="p-button-text"
            data-testid="buttonCancelAppendDialog"
            @click="showAppendModal = false"
          />
          <Button
            :label="translation.buttonAppend"
            type="submit"
            icon="pi pi-check"
            class="p-mr-0"
            data-testid="buttonAppend"
            @click="onAppendRecord"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="showInvitationModal"
        :style="{ width: '450px' }"
        :header="translation.dialogInvitationTitle"
        :modal="true"
        class="p-fluid p-pb-0"
        ><div>
          {{
            $t("customer.accounting.send.description", {
              email: customer.contact.email,
            })
          }}
        </div>
        <template #footer>
          <Button
            :label="translation.buttonCancel"
            icon="pi pi-times"
            class="p-button-text"
            data-testid="buttonCancelSendDialog"
            @click="showInvitationModal = false"
          />
          <Button
            :label="translation.buttonSendInvitation"
            type="submit"
            icon="pi pi-check"
            data-testid="buttonSendInvitation"
            @click="onSendInvitation"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import Toolbar from "primevue/toolbar";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../config";
import Record from "../models/Record";
import { storageTransformer } from "../service/transformer/storageTransformer";
import { dateTransformer } from "../service/utils";
import { APPEND_RECORD } from "../store/modules/accounting/accounting.actions.type";
import { GET_RECORDS } from "../store/modules/accounting/accounting.getters.type";
import { USER_SEND_INVITATION } from "../store/modules/auth/auth.actions.type";
import { GET_CUSTOMER_BY_ID } from "../store/modules/customer/customer.getters.type";
import { useExpireCalendar } from "./useExpireCalendar";

export default {
  name: "TableAccounting",
  components: {
    Button,
    Calendar,
    Column,
    DataTable,
    Dialog,
    InputNumber,
    InputText,
    Toolbar,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { customerId } = route.params;
    const record = ref(Record.empty());
    const records = computed(() => store.getters[GET_RECORDS]);
    const increasedTimeInMin = ref(record.value.increaseTime / 60);
    const maxStorageInGB = ref(
      storageTransformer.byteToGiga(record.value.maxStorage)
    );
    const showAppendModal = ref(false);
    const showInvitationModal = ref(false);
    const customer = computed(() =>
      store.getters[GET_CUSTOMER_BY_ID](customerId)
    );
    const translation = {
      time: t("customer.accounting.table_column_time"),
      storage: t("customer.accounting.table_column_storage"),
      description: t("customer.accounting.table_column_description"),
      createdAt: t("customer.accounting.table_column_created_at"),
      expires: t("customer.accounting.table_column_expires_at"),
      dialogAppendTitle: t("customer.accounting.new.dialog_title"),
      dialogInvitationTitle: t("customer.accounting.send.dialog_title"),
      buttonAppend: t("common.append"),
      buttonCancel: t("common.cancel"),
      buttonSendInvitation: t("common.send"),
      buttonRecord: t("customer.accounting.button_record"),
      buttonInvitation: t("customer.accounting.button_invitation"),
    };
    const { minDate, rangeYears } = useExpireCalendar();

    const stringyDate = (date) => dateTransformer.toString(date);
    const stringyStorage = (storage) =>
      storageTransformer.humanReadable(storage);
    const stringyTime = (time) =>
      `${dateTransformer.secondsToMinutes(time)} min.`;

    const onCustomers = () => router.push({ name: "customers" });

    const onAppendRecord = async () => {
      record.value.increaseTime = increasedTimeInMin.value * 60;
      record.value.maxStorage = storageTransformer.gigaToBytes(
        maxStorageInGB.value
      );
      await store.dispatch(APPEND_RECORD, {
        accounting: customerId,
        record: record.value,
      });
      toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: t("customer.accounting.new.toast_success"),
        life: TOAST_LIFE,
      });
      showAppendModal.value = false;
      record.value = Record.empty();
    };

    const onSendInvitation = async () => {
      showInvitationModal.value = false;
      await store.dispatch(USER_SEND_INVITATION, customer.value.contact.email);
      toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: t("customer.accounting.send.toast_success"),
        life: TOAST_LIFE,
      });
    };

    return {
      minDate,
      rangeYears,
      increasedTimeInMin,
      maxStorageInGB,
      records,
      record,
      customer,
      translation,
      stringyDate,
      stringyTime,
      stringyStorage,
      onAppendRecord,
      onCustomers,
      onSendInvitation,
      showAppendModal,
      showInvitationModal,
    };
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
